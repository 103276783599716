<template>
  <div>
    <v-btn
      :href="ipchainUrl"
      :ripple="false"
      :small="small"
      :x-small="xSmall"
      class="text-no-wrap"
      target="_blank"
      text
    >
      <v-icon
        :small="small"
        :x-small="xSmall"
        color="primary"
        left
      >
        {{ icons.open }}
      </v-icon>
      {{ $t('View in IPChain') }}
    </v-btn>
  </div>
</template>

<script>
import {mdiOpenInNew} from '@mdi/js'

export default {
  name: 'DepositLinkIpchain',
  props: {
    deposit: {type: Object, required: true},
    small: {type: Boolean, default: false},
    xSmall: {type: Boolean, default: false}
  },
  data () {
    return {
      icons: {
        open: mdiOpenInNew
      }
    }
  },
  computed: {
    ipchainUrl () {
      return this.deposit.ipchain?.IPChainLink || ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
